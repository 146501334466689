<template>
	<div class="query-statistics">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/queryStatistics' }">查询统计</el-breadcrumb-item>
					<el-breadcrumb-item>{{this.$route.query.name}}</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
					<el-form-item>
						<div class="strip">总计：<span>15583</span>条</div>
					</el-form-item>
					<el-form-item>
						<el-input v-model="formInline.user" placeholder="请输入关键字"></el-input>
					</el-form-item>
					<el-form-item>
						<el-date-picker v-model="formInline.time" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column prop="product.model" label="备件号"></el-table-column>
				<el-table-column prop="product.name" label="备件名称"></el-table-column>
				
				<el-table-column prop="modifyTime" label="查询时间" sortable align="center"></el-table-column>
				<!-- <el-table-column label="操作" width="200" align="center" class-name="table-operate">
					<template slot-scope="scope">
						<el-button class="operation-btn" size="small" title="账号设置" type="primary"
							@click="editClick(scope.row)">账号设置</el-button>
						<el-button type="danger" size="small" title="删除" @click="deleteClick(scope.row)">删除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import http from "../../http.js";
	export default {
		components: {},
		data() {
			return {
				formInline: {
					user: '',
					region: ''
				},
				tableData: [],
				query: {
					page: 1,
					pagesize: 10,
					sort: '-id',
					status: 2
				},
				total: ''
			};
		},
		created() {
			this.getList();
			document.addEventListener('visibilitychange', this.handleVisiable)
		},
		destroyed() {
			document.removeEventListener('visibilitychange', this.handleVisiable)
		},
		methods: {
			onSubmit() {
				console.log('submit!');
			},
			handleVisiable(e) {
				if (e.target.visibilityState === 'visible') {
					this.getList()
				}
			},
			indexMethod(e) {
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
				http.fetchGet("/api/admin/product/modify/list", this.query).then((res) => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.list;
						this.total = res.data.data.total
					}
				});
			},
			editClick() {
				this.$router.push("/accountDetails")
			},
			deleteClick() {
				this.$confirm("确定要永久删除该行备品吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$message({
							type: "success",
							message: "删除成功!",
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			handleSort(e) {
				console.log(e);
				if (e.order == "ascending") {
					this.query.sort = "+" + e.prop
				} else if (e.order == "descending") {
					this.query.sort = "-" + e.prop
				} else {
					this.query.sort = ""
				}
				// this.query.page = 1
				this.getList()
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
